<template>
  <div>
    <v-alert
      type="error"
      v-if="error"
      tile
      colored-border
      border="left"
      class="grey lighten-5"
    >
      {{ error }}
    </v-alert>
    <div v-if="emailSent">
      <animated-tick />
      <h3 class="mb-4 text-center">Password reset email sent!</h3>
      <p>
        You should receive an email from <b>Willed</b> within a minute. This
        email will contain a link you can click on to access the password reset
        form.
      </p>
      <p>If you are unable to find the email, try checking your spam folder.</p>
      <div class="pa-4 bg-grey-10 text-center text-sm">
        Got questions? Contact us on
        <a href="tel:1300945533">1300 945 533</a>,
        <a href="mailto:hello@willed.com.au">hello@willed.com.au</a> or via the
        live chat available on every page.
      </div>
    </div>

    <div v-else>
      <v-alert
        type="error"
        v-if="genericErrorOccured"
        tile
        colored-border
        border="left"
        class="grey lighten-5"
      >
        Error, could not send the email for reset password. You might have
        entered the wrong email address.
      </v-alert>

      <v-alert
        type="error"
        v-if="rateLimitReached"
        tile
        colored-border
        border="left"
        class="grey lighten-5"
      >
        Error, You have exceeded the reset password limit. Please come back
        later and try again.
      </v-alert>

      <h3 class="mb-4">Email Address</h3>

      <v-text-field
        v-model="local.email"
        type="email"
        outlined
        @blur="showErrors.email = true"
        @focus="showErrors.email = false"
        :error-messages="
          showErrors.email && errorMessages.email ? errorMessages.email : null
        "
      ></v-text-field>
      <v-btn
        v-bind="largeButton"
        @click="submit"
        x-large
        :loading="processing"
      >
        Reset
      </v-btn>
    </div>
  </div>
</template>

<script>
import AnimatedTick from '../../../components/ui/AnimatedTick.vue'

export default {
  name: 'ResetPassword',
  components: { AnimatedTick },
  data: function () {
    return {
      local: {
        email: '',
      },
      showErrors: {
        email: false,
      },
      processing: false,
      emailSent: false,
      rateLimitReached: false,
      genericErrorOccured: false,
    }
  },
  props: {
    parentWindow: String,
  },
  watch: {
    parentWindow: function () {
      if (this.parentWindow !== 'reset') {
        this.emailSent = false
        this.rateLimitReached = false
        this.genericErrorOccured = false
      }
    },
  },
  computed: {
    buttonType() {
      if (this.processing) return this.btnProcessing
      if (this.isValid) return this.btnActive
      return this.btnInactive
    },
    errorMessages() {
      const msgs = {
        email: null,
      }

      if (this.local.email === '') {
        msgs.email = 'Required field'
      }

      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      if (!msgs.email && !pattern.test(this.local.email)) {
        msgs.email = 'Not a valid email address'
      }

      return msgs
    },
    isValid() {
      return Object.values(this.errorMessages).every((val) => val === null)
    },
    error() {
      return this.$store.getters['app/app_message'].text
    },
    localFormatted() {
      return {
        email: this.local.email.trim(),
      }
    },
  },
  methods: {
    submit() {
      this.showErrorsSwitch()
      if (this.isValid) {
        this.processing = true
        this.$store
          .dispatch('account/auth_reset_password', this.localFormatted)
          .then(() => {
            this.emailSent = true
            this.processing = false
          })
          .catch((error) => {
            if (
              error.message === 'RESET_PASSWORD_EXCEED_LIMIT' ||
              error.code === 'auth/too-many-requests'
            )
              this.rateLimitReached = true
            else this.genericErrorOccured = true
            this.processing = false
          })
      }
    },
    showErrorsSwitch(show = true, field = null) {
      if (field) {
        this.showError[field] = show
        return
      }

      Object.keys(this.showErrors).forEach((key) => {
        this.showErrors[key] = show
      })
    },
  },
}
</script>
