<template>
  <div class="pa-6">
    <v-window
      v-model="window"
      touchless
    >
      <v-window-item value="login">
        <transition name="fade">
          <div class="auth-form-inner">
            <div class="w-title font-weight-bold mb-8 font-cooper">Login</div>
            <login-form />
            <div class="mt-6 text-center">
              <p>
                Forgot your password?
                <a
                  @click="window = 'reset'"
                  class="mb-2"
                  >Reset password</a
                >
              </p>
              <p>
                Don't have an account?
                <a
                  @click="$router.push('/get-started')"
                  class="mb-2"
                  >Sign up</a
                >
              </p>
            </div>
          </div>
        </transition>
      </v-window-item>
      <v-window-item value="reset">
        <div>
          <v-chip
            @click="window = 'login'"
            text-color="black"
            color="white"
            class="font-weight-medium back-btn"
          >
            <v-avatar left>
              <v-icon>mdi-chevron-left</v-icon>
            </v-avatar>
            Back
          </v-chip>
        </div>
        <div class="auth-form-inner">
          <div class="text-h2 mb-8">Reset Password</div>
          <reset-password :parent-window="window" />
          <div class="mt-6 text-center">
            <p>
              Ready to login?
              <a
                @click="window = 'login'"
                class="mb-2"
                >Login</a
              >
            </p>
          </div>
        </div>
      </v-window-item>
    </v-window>
  </div>
</template>

<script>
import LoginForm from './forms/Login'
import ResetPassword from './forms/ResetPassword'

export default {
  name: 'Login',
  components: {
    ResetPassword,
    LoginForm,
  },
  data: function () {
    return {
      window: 'login',
    }
  },
}
</script>
<style lang="scss">
h1 {
  font-size: 36px !important;
  font-weight: 500;
  line-height: 48px !important;
}
</style>
